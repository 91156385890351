export const AddressConfig = {
  // test env
  ekey: '0x002F564D3382742179Bc51C7c753797fF90b71b6',
  wkey: '0x521D21A33Afd761659Ede5Ea5Db67855D225779c',
  ltkey: '0x2ba2475Faf9c3FBBb3a3116B29d1e9C841B4fbCc',
  passcard: '0x3e663304b90685253a984448e59eE271282A8A14',
  predictionETH: '0xD1c6669Fd716C3a1B02D2a3198f37a00cc6dF547',
  lottery: '0x116878ffD652C158Aaf6d8B849BC6A09C6E5c680',

  oracleETH: '0x639fe6ab55c921f74e7fac1ee960c0b6293ba612',
}

export const predictionConfig = {
  maxReturnRate: 2,
}

export const GraphConfig = {
  // test env
  predictionETH: 'https://api.studio.thegraph.com/query/45302/tprediction-eth/v0.0.6',
  lottery: 'https://api.studio.thegraph.com/query/45302/tlottery/v0.0.4',
  passcard: 'https://api.studio.thegraph.com/query/45302/tpasscard/v0.0.5',
}
